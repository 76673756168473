.page {
}
.row {
  padding-block: 30px;
}
.sectionHeading {
  text-transform: capitalize;
  margin-bottom: 30px;
}
.sectionHeading span {
  font-weight: 500;
}

.detailMainContainer {
  display: block;
  place-items: center;
  background: white;
  box-shadow: 0px 0px 14px #00a99733;
}
.detailContainer {
  padding-inline: 10px;
}
.detailContainer h6 {
  font-size: 24px;
  margin-block: 15px;
}
.detailContainer p {
  font-size: 15px;
  margin: 0px !important;
  margin-bottom: 10px !important;
  white-space: pre-line;
  line-height: 26px;
}
.imageContainer {
  width: 100%;
  height: 400px;
}

.imageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}
.bottomContainer {
  border-top: 2px solid #cccccc;
  padding-block: 10px;
  margin-inline: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.socialIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.socialIconContainer p {
  margin: 0px !important;
}
.socialIcon {
  width: 30px !important;
  height: 30px !important;
  margin-left: 5px;
}
.authorContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.authorContainer p {
  margin: 0px !important;
  font-size: 14px;
}
.authorContainer svg {
  margin-right: 5px;
}
.authorContainer p:first-of-type {
  margin-right: 15px !important;
}

.banner {
  margin-block: 30px;
}
.banner img {
  height: 100%;
  width: 100%;
}

.tabsWrapper {
  border-bottom: 1px solid var(--primary-clr);
  /* margin-top: 40px; */
  margin-bottom: 20px;
}
.tabsContainer {
  width: max-content;
  margin-bottom: 0;
}

.tabsContainer li {
}
.tabsContainer li span {
  margin: 0 auto;
}

.blogTitleCard {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.blogTitleCardImage {
  width: 120px;
  height: 60px;
  margin-right: 10px;
}
.blogTitleCardImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blogTitleCardText p {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 18px;
}

.loader {
  min-height: calc(100vh - 80px);
}

.navbarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px;
  max-width: 100% !important;
  box-shadow: 0px 3px 6px #00000029;
  position: sticky;
  top: 0;
  background-color: var(--white-clr);
  z-index: 10;
}

.logo {
  width: 100px;
  height: 50px;
}

.wrapper {
  font-family: 'Inter', sans-serif;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header svg {
  display: inline-block;
  vertical-align: top;
}

.mainColor {
  color: #4d55e5;
  cursor: pointer;
}

.linkContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.linkContainer span {
  margin-right: 10px;
}

.logoImage {
  width: 156px;
  height: 36px;
  vertical-align: middle;
}

.goBackLink {
  padding-left: 30px;
}

.nabarLinks {
  color: var(--text-black-clr) !important;
  font-family: 'plus-jakarta-display-regular';
  font-size: 18px !important;
  padding: 8px 0px;
  text-decoration: none;
  line-height: 1.1;
  font-weight: 600;
}

.nabarLinks:hover {
  transition: all 0.4s ease-in-out;
  color: var(--main-color) !important;
}

.navActive {
  color: var(--main-color) !important;
  border-bottom: 2px solid var(--main-color);
}

.signin_button {
  background-color: var(--button-color);
  border: 1px solid var(--main-color);
  color: var(--text-black-clr);
  margin-right: 14px;
}

.btn {
  padding: 10px 30px !important;
  border-radius: 30px;
  font-family: 'plus-jakarta-display-regular';
}

.signup_button {
  background-color: var(--main-color);
  color: var(--white-clr);
  border: 1px solid var(--main-color);
}

.gapCustm {
  gap: 3rem;
}

.dflex {
  display: flex;
}

.signup_button {
  color: var(--white-clr) !important;
}

.profileImg {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  /* border: 2px solid #929eaa; */
  background-color: #d6d6d6;

  box-shadow: 7px 7px 22px #00000017;
  user-select: none;
}

.profileImg img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: top center;
  cursor: pointer;
}

.iconDiv {
  display: flex;
  align-items: center;
  margin-right: 12px;
  user-select: none;
  cursor: pointer;
}
.aCenter{
  display: flex;
  align-items: center;
  row-gap: 22px;
}

.notifyoverlayDiv {
  top: 10px !important;
  left: -90px !important;
  z-index: 10;
}

.SettingsoverlayDiv {
  top: 12px !important;
  left: -10px !important;
  z-index: 2;
}

.SettingsPopup {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 0 4px 0 #0000004a;
  padding: 12px 30px;
  width: max-content;
}

.editProfileIcons {
  vertical-align: bottom !important;
  margin-right: 5px;
}

.editprofileDiv {
  margin-bottom: 10px;
  color: var(--text-dark-gray-clr);
  cursor: pointer;
}

.notiIcon {
  width: 42px;
  height: 42px;
  /* UI Properties */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.notiIcon[data-unread='true']::before {
  background-color: var(--primary-clr);
  position: absolute;
  top: 1px;
  right: 4px;
  width: 15px;
  height: 15px;
  content: '';
  border-radius: 15px;
}
.logoDiv {
  width: auto;
  height: 50px;
  margin-inline-end: 80px;
}
.logoDiv img {
  width: 100%;
  height: 100%;
}
@media (max-width: 1200px) {
  .gapCustm {
    gap: 1.5rem;
  }
}

@media (max-width: 1080px) {
  .gapCustm {
    gap: 1rem;
  }

  .nabarLinks {
    font-size: 16px !important;
  }

  .iconDiv svg {
    width: 25px !important;
    height: 25px !important;
  }

  .iconDiv svg {
    /* margin-right: 10px; */
  }

  .profileImg {
    width: 50px;
    height: 50px;
  }
}

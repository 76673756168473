/*  */
.container-table100 {
  width: 100%;
  min-height: 100vh;
  background: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 33px 30px;
}

.wrap-table100 {
  width: 1170px;
}



table {
  width: 100%;
}

th,
td {
  font-weight: unset;
  padding-right: 10px;
}

.column1 {
  width: 20%;
  padding-left: 40px;
}

.column2 {
  width: 20%;
}

.column3 {
  width: 20%;
}

.column4 {
  width: 20%;
}

.column5 {
  width: 20%;
}

.table100-head th {
  padding-top: 18px;
  padding-bottom: 18px;
}

.table100-body td {
  padding-block: 20px;
}

.table100 {
  position: relative;
  /* padding-top: 60px; */
}

.table100-head {
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;

}

.table100-body {
  max-height: 70vh;
  overflow: auto;
}

.table100.ver1 th {
  font-family: "Poppins-medium";
  color: var(--white-clr);
  font-size: 14px;
  /* line-height: 22px; */
}

.table100.ver1 td {
  font-family: "Poppins-medium";
  font-size: 14px;
  /* line-height: 19px; */
  color: var(--dashboard-text-color);
  text-transform: capitalize;
}

.table100.ver1 .table100-body tr {
  display: flex;
  /* margin: 0px 15px; */
  align-items: center;
  text-align: center;
  border-radius: 10px;
  /* box-shadow: 0px 5px 25px #52575d1a; */
  /* background-color: var(--white-clr); */
  color: var(--text-black-clr);
}

.table100 .table100-head tr {
  display: flex;
  align-items: center;
  text-align: center;
  /* margin: 0px 15px; */
  border-radius: 10px;
  background: transparent linear-gradient(90deg, var(--primary-clr) 0%, var(--secondary-clr) 100%) 0% 0% no-repeat padding-box;
  padding-block: 10px;
}

.table100.ver1 {
  border-radius: 20px;
  /* overflow: hidden; */
}

.table100.ver1 table tbody tr:nth-child(even) {
  background: #F3D6F5;
}

/* width
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ebebeb;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
} */
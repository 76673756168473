.main {
  max-width: 96% !important;
  padding-block: 40px;
  height: 100vh;
  overflow-y: auto;
}
.main h5 {
  color: var(--dashboard-text-gray-clr);
  margin-bottom: 0px;
}
.quillInput {
  height: calc(100vh - 300px);
  background-color: var(--white-color);
  border: 1px solid var(--7070-clr) !important;
  box-shadow: unset !important;
  border-radius: 10px;
}
.row {
  row-gap: 10px;
}
.mt30 {
  margin-top: 30px;
}
.btnGroup{
  border: 1px solid var(--primary-clr);
  border-radius: 10px;
}
.btnGroup button:first-child::before{
  background-color: var(--primary-clr);
  content: " ";
  position: absolute;
  right: 0px;
  width: 1px;
  height: 100%;
  top: 0px;

}
.btnGroup button:first-child {
  border-radius: 10px 0px 0px 10px;
  position: relative;
  background-color: transparent;
  min-width:120px;
  
}

.btnGroup button:last-child {
  border-radius: 0px 10px 10px 0px;
  background-color: transparent;
  min-width:120px;

}

.btnGroup button[data-selected="true"]{
  background-color: var(--primary-clr);
  color: var(--white-clr);
}

.spBtw{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
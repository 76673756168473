.paginationDiv {
    margin-top: 30px;
    /* display: flex; */
    /* justify-content: center; */
  }
  
  .pageItem>ul>li:first-child button svg,
  .pageItem>ul>li:last-child button svg {
    font-size: 25px;
  }
  
  .pageItem>ul>li:not(:first-child)>button {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
  }
  
  @media screen and (max-width: 768px) {
    .pageItem>ul>li:not(:first-child)>button {
      width: 32px;
      height: 32px;
      border-radius: 32px;
      line-height: 32px;
      font-size: 18px;
  
    }
  }
  
  @media screen and (max-width: 425px) {
    .pageItem>ul>li:not(:first-child)>button {
      width: 30px;
      height: 30px;
      border-radius: 30px;
      line-height: 30px;
      font-size: 17px;
  
    }
  }
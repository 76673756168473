.main {
  /* height: 500px; */
  width: 100%;
  padding: 0px !important;
}

.paddingContainer {
  padding: 50px 70px;
}

.header {
  border-radius: 0px;
  height: 30px;
}
.header > div {
  top: 3px;
  right: 10px;
}
.row {
  height: 100%;
}

.col1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding-right: 30px;
  margin-top: 20px;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.buttonsContainer button {
  width: 200px;
}

.buttonsContainer button:first-child {
  background-color: var(--white-clr);
  color: var(--primary-clr);
  border: 1px solid var(--primary-clr);
}

.previewContainer h4 {
  color: var(--primary-clr);
}

.page{
    padding: 45px;
}
.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header .left{
    display: flex;
    align-items: center;
    column-gap: 24px;
}
.header .left .dropDownContainer{
    flex-direction: row;
    align-items: center;
    column-gap: 10px;
}
.header .left .dropDownContainer label{
    flex-shrink: 0;
    margin-bottom: 0px;
}

.headerRight{
    display: flex;
    align-items: center;
    column-gap: 24px;
}
.headerBtn{
    width: max-content !important;
}
.deleteBtn{
    width: max-content !important;
    background-color: #E95A64 !important;
}
.deleteBtn:not(:disabled):hover{
    background-color: #e95a64cc !important;
}


.heading{
    color: var(--dashboard-text-gray-clr);
    margin-bottom: 32px;
}
.mb58{
    margin-bottom: 58px;
}
.cardsRow{
    row-gap: 50px;
}
.cardWithCheckbox{
    display: flex;
    column-gap: 20px;
    align-items: self-start;
}
.cardWithCheckbox .checkbox{
    padding: 0px;
}
.paginationDiv{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
.loader{
    min-height: calc(100vh - 400px);
}

.content{
    min-height: calc(100vh - 420px);

}
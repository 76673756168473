.mainContainer {
  width: 100%;
  box-shadow: 0px 4px 20px 10px rgb(0 0 0 / 6%);
  position: sticky;
  top: 80px;
  background: var(--white-color);
  height: calc(100vh - 80px);
  padding-top: 80px;
  padding-bottom: 20px;
  overflow-y: auto;

}

.itemsContainer {
  overflow-y: scroll;
}

.listItemContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-block: 7px;
  padding-inline-start: 20px;
  margin-block: 8px;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  border-inline-start: 6px solid var(--white-clr)
}
.listItemContainer svg{
  width: 25px;
  color:var(--sidebar-text-clr);
}
.listItemContainer svg path{
  color:var(--sidebar-text-clr);
}

.marginZero {
  margin-block: 0px !important;
}

.listItemContainer span {
  font-size: 16px;
  color: var(--sidebar-text-clr);
  margin-inline-start: 18px;
}

.dropDownIcon {
  position: absolute;
  right: 15px;
  top: 22%;
}

.innerItemContainer {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-inline-start: 60px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
}

.innerItemContainer span {
  font-size: 14px;
  color: var(--sidebar-text-clr);
  margin-inline-start: 18px;
}
.innerItemContainer svg{
  color: var(--sidebar-text-clr);
}
.active{
  border-inline-start: 5px solid var(--primary-clr);
}
.active span {
  color:var(--primary-clr);
}
.active{
  background-color: #029a8a1f !important;

}
.active svg path {
  fill: var(--primary-clr) ;
}

.subActive span {
  color:var(--sidebar-text-clr);
  /* text-decoration: underline; */
}

.subActive svg {
  color: #ffffff !important;
}

::-webkit-scrollbar {
  display: none;
}

.phoneInput {
  font-family: "Poppins-semiBold";
  font-size: 16px !important;
  color: var(--text-black-clr);
  letter-spacing: 1.4px !important;
  background-color: #fff ;
  width: 100% !important;
  min-height: 48px !important;
  border: none !important;
}

.phoneInputContainer {
  background-color: var(--white-clr);
  box-shadow: 0px 0 5px 2px #0000000d;
  border-radius: 10px;

}

.phoneInput:focus {
  box-shadow: none !important;
}

.phoneInput::placeholder {
  color: var(--placeholder-color);
  font-family: "Poppins-medium";
  font-size: 16px;
  text-transform: capitalize;
}

.phoneLabel {
  text-transform: capitalize;
  /* font-family: "Poppins-bold"; */
  font-size: 18px;
  color: var(--dashboard-main-color);
  margin-bottom: 8px;
}

.labelDisabled {
  color: #ababab;
}


.phoneMain[data-variant='dashboard'] .phoneInput{
  min-height: 55px !important;
  background-color:var(--dashboard-input-bg-clr) !important;
  border: none;

}

.phoneMain[data-variant='dashboard'] .phoneLabel{
  color: var(--dashboard-input-label-clr);
  font-size: 18px;
  font-weight: 600;
}
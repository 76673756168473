.page {
  padding: 45px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.loader{
  min-height: calc(100vh - 80px);
}

.heading {
  color: var(--dashboard-text-gray-clr);
}
.mb58 {
  margin-bottom: 58px;
}
.backBtn {
  color: var(--primary-clr) !important;
  padding: 0px;
  font-weight: 500;
}
.backBtn span {
  font-weight: inherit;
}
.mb30 {
  margin-bottom: 30px;
}
.formBg {
  background-color: var(--white-clr);
  box-shadow: 0px 3px 6px #00000029;
  padding-block: 60px;
  margin-bottom: 30px;
}

.formBg h3 {
  color: var(--primary-clr);
  font-size: 35px;
}
.formBg h4 {
  color: var(--dashboard-text-gray-clr);
  font-size: 35px;
  padding-bottom: 15px;
  font-weight: 600;
  font-size: 24px;
}
.formRow {
  row-gap: 30px;
}
.btnDiv {
  display: flex;
  justify-content: space-around;
}
.btnDiv button:nth-child(1) {
  background-color: var(--dashboard-input-bg-clr);
  color: var(--text-black-clr);
}

.btnDiv .btn {
  padding: 16px 60px;
}
.linkAndUpload {
  display: flex;
  align-items: center;
  width: 100%;
}
.linkAndUpload span {
  padding-inline: 7px;
}
.linkAndUpload .linkInput {
  width: calc(50% - 18px);
}

.linkAndUpload .fileUpload {
  padding-block: 3.5px;
  width: calc(50% - 18px);
}

.phoneInput {
  background-color: var(--dashboard-input-bg-clr) !important;
}
.label {
  color: var(--dashboard-input-label-clr) !important;
  font-size: 18px;
  font-weight: 600;
  font-family: inherit;
}
.spBtw {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

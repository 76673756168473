.main {
  /* height: 500px; */
  width: 100%;
  padding: 0px !important;
}

.paddingContainer {
  padding: 50px 70px;
}

.header {
  border-radius: 0px;
  height: 30px;
}
.header > div {
  top: 3px;
  right: 10px;
}
.title{
  display: flex;
  justify-content: space-between;
}
.row {
  height: 100%;
  row-gap: 10px;
}

.col1 {
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  /* gap: 10px; */
  /* padding-right: 30px; */
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.buttonsContainer button {
  width: 200px;
}

.buttonsContainer button:first-child {
  background-color: var(--white-clr);
  color: var(--primary-clr);
  border: 1px solid var(--primary-clr);
}

.previewContainer h4 {
  color: var(--primary-clr);
}
.labelAndValue {
}
.labelAndValue label {
  color: var(--dashboard-input-label-clr);
  font-size: 18px;
  font-weight: 600;
  display: inline-block;

}
.labelAndValue p {
  font-size: 16px;
  margin-bottom: 0px;
  display: inline-block;
}

.main {
  /* height: 500px; */
  width: 100%;
  padding: 0px !important;
}

.paddingContainer {
  padding: 50px 70px;
}
.upload_img_cus img {
  object-fit: contain;
  object-position: top center;
  border-radius: inherit;
  background-color: #00a9975c;
}
.header {
  border-radius: 0px;
  height: 30px;
}
.header > div {
  top: 3px;
  right: 10px;
}
.row {
  height: 100%;
}

.iconsWrap {
  display: flex;
  gap: 30px;
}
.iconsWrap p {
  font-size: 12px;
  text-align: center;
}
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 57px;
  width: 57px;
  background-color: #029a8a;
  border-radius: 50%;
}
.sideImage {
  /* padding-top: 100px; */
}
.sideImage img {
  height: 100%;
  width: 100%;
}

.col1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding-right: 30px;
}

.breadCrumb {
  background-color: var(--primary-clr);
  padding: 10px 25px;
  border-top-right-radius: 100vw;
  border-bottom-right-radius: 100vw;
  margin-top: 30px;
  width: fit-content;
}
.breadCrumb {
  font-size: 22px;
  color: var(--white-clr);
  text-align: center;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.buttonsContainer button {
  width: 200px;
}

.buttonsContainer button:first-child {
  background-color: var(--white-clr);
  color: var(--primary-clr);
  border: 1px solid var(--primary-clr);
}

.previewContainer h4 {
  color: var(--primary-clr);
}

.horizontalImg {
  width: 100%;
  height: 230px;
}

.horizontalImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.verticalBanner {
  width: 250px;
  height: 550px;
  margin: 0 auto;
}

.btnCol {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btnCol button {
  width: 45%;
  border-radius: 10px;
}

.inputCol {
  margin-bottom: 20px;
}

.bulletContainer {
  position: relative;
  display: flex;
  align-items: center;
  background-color: var(--white-color);
  box-shadow: 0px 0 5px 2px #0000000d;
  border-radius: 10px;
  padding: 15px;
  margin-top: 15px;
  justify-content: space-between;
}

.bulletContainer span {
  font-family: "Open-Sans-regular";
  font-size: 14px;
  color: var(--text-color-black);
  letter-spacing: 1.4px;
  width: 100%;
  margin: 0px 10px;
}

.inputAndAddBtnContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.titleText {
  display: contents;
  color: var(--dashboard-main-color);
  text-transform: capitalize;
  font-family: "Open-Sans-bold";
  font-size: 16px;
  margin-bottom: 10px;
}

.icon {
  cursor: pointer;
}

.bulletInnerContainer {
  display: flex;
  align-items: center;
}

.mainNotesDiv {
  height: 400px;
  overflow-y: auto;
}

/* Color Picker */
.labelText {
  color: var(--secondary-color);
  text-transform: capitalize;
  font-family: "Poppins-semiBold";
  font-size: 16px;
  margin-bottom: 10px;
}
.colorInput {
  width: 50% !important;
  margin-bottom: 20px !important;
}
.colorInput :global(.MuiInputBase-root) {
  border-radius: 10px !important;
  overflow: hidden;
}
.colorInput :global(.MuiInputBase-input) {
  padding: 0;
  height: 45px;
  border: none !important;
}

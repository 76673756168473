.ul {
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.ul li {
  width: 50px;
  height: 40px;
  cursor: pointer;
  padding: 5px;
}

.ul li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ul li[data-selected="true"] {
  background: #80cfe7;
  border-radius: 5px;
}

.quillInput {
  box-shadow: 0px 0 5px 2px #0000000d;
  border-radius: 10px;
  overflow-y: scroll;
}

.quillInput ul li {
  list-style-type: unset;
}

.disabledQuillInput {
  background: var(--disabled-input-color) !important;
  color: #c9c9c9;
  cursor: not-allowed;
}

.label {
  color: var(--primary-clr);
  text-transform: capitalize;
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: 600;
}

.quill {
}

.ckEditorBox {
}

.quillInput > div > div:nth-child(1) {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border: none;
  border-bottom: 1px solid var(--text-color-black);
  background: #e0f3f1;
}

.quillInput > div > div:nth-child(2) {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  height: calc(100% - 42px);
  min-height: 150px;
  overflow-y: scroll;
  border: none;
}

.CKEditor {
}

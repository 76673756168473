.page {
  padding: 45px;
}

.heading {
  color: var(--dashboard-text-gray-clr);
}

/* Table */
.tableWrapper {
}
.tableWrapper p {
  margin-bottom: 0px;
}

.thead {
  height: 70px;
}
.thead > tr th {
  background-color: #e0f3f1;
  color: var(--575-clr);
  font-weight: 600;
  text-align: center;
}
.tableHeight {
  height: calc(100vh - 365px);
}

/* Table */

.tableCol1Text {
  font-size: 17px;
  color: var(--dashboard-text-gray-clr);
  font-family: "Poppins-medium";
}
.tableRow td:not(:first-child) {
  border: 1px solid #e4e6e7;
}
.tableRow {
  height: 185px;
}

.tableRow td:first-child {
  background-color: #e0f3f1;
  border-bottom: 0px;
}

.inputWithBtn {
  display: flex;
  column-gap: 10px;
  align-items: center;
}
.inputWithBtn .input {
  width: 100%;
}
.inputWithBtn .input input {
  border: none;
  font-size: 17px;
  color: var(--dashboard-text-gray-clr);
  font-family: "Poppins-medium";
}
.inputWithBtn .input:disabled {
  background-color: transparent;
}
.inputWithBtn svg{
  cursor: pointer;
}

.loader{
  min-height: calc(100vh - 80px);
}
.row_main {
    row-gap: 30px;
  }
  .comboRootClassName {
    left: -50% !important;
    right: -50% !important;
  }
  
  .row_main h4 {
    color: var(--dashboard-text-gray-clr);
    font-size: 35px;
    font-weight: 600;
    font-size: 24px;
  }
  
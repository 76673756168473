.btn {
  outline: none;
  border: none;
  font-family: "Poppins-regular";
  padding: 15px 20px;
  line-height: 1;
  font-size: 16px;
  border-radius: 5px;

}

.btn[color-variant='primary'] {
  background-color: var(--primary-clr);
  color: var(--white-clr);
}

.btn[color-variant='secondary'] {
  background-color: var(--secondary-clr);
  color: var(--white-clr);
}

.btn:disabled{
  opacity: 0.8;
  cursor: not-allowed;
}
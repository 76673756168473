/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

:root {
  --dashboard-color: #000000;
  --secondary-clr: #245357;
  --text-dark-gray: #808080;

  --placeholder-color: #c9c9c9;
  --label-color: #9b9b9b;

  --primary-clr: #00a997;
  --secondary-clr: #f28e1d;
  --sidebar-text-color: #9b9b9b;
  --text-color: #4a4a4a;

  --primary-clr-hover: #04a291;
  --secondary-clr-hover: #f6992f;
  --white-clr: #fff;
  --text-black-clr: #000;
  --icon-count-clr: #ff0000;
  --text-dark-gray-clr: #656565;
  --text-light-gray-clr: #a8a8a8;
  --input-label-color: #000;
  --placeholder-clr: #8b8b8b;
  --input-border-clr: #ccc;
  --disabled-input-clr: #fafafa;
  --btn-primary-color: #ee8a1d;
  --btn-secondary-color: #ea2344;
  --btn-color-grey: #868686;

  --arrow-color: #e8871c;
  --border-color: #adacad;
  --border-light-color: #e6eaeb;

  --page-bg-color: #ebebeb;

  /* New Colors */
  --sidebar-text-clr: #6a707e;
  --footer-text-clr: #d1d1d1;
  --disabled-label-clr: #ababab;
  --error-clr: red;
  --list-item-border-clr: #a8a8a838;
  --ccc-clr: #ccc;
  --d8d-clr: #ccc;
  --dashboard-page-bg-clr: #f3f6f9;
  --575-clr: #575757;
  --dashboard-text-gray-clr: #847c7c;
  --dashboard-input-label-clr: #029a8a;
  --dashboard-input-bg-clr: #e0f3f1;
  --action-icon-clr: #00a997;
  --card-shadow: 0 3px 3px -3px rgba(0, 0, 0, 0.3);
  --7070-clr: #707070;
}

/* Bebas-Neue */
.svgIcon[data-active=true] path{
  color: var(--primary-clr) !important;
  fill: unset !important;
  
}
@font-face {
  font-family: "Poppins-bold";
  src: url("../fonts/Poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: "Poppins-semiBold";
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: "Poppins-medium";
  src: url("../fonts/Poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins-regular";
  src: url("../fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: "Poppins-italic";
  src: url("../fonts/Poppins/Poppins-Italic.ttf");
}

/*//////////////////////////////////////////////////////////////////
  [ RESTYLE TAG ]*/

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

/*---------------------------------------------*/
.t-t-c {
  text-transform: capitalize;
}
.jEnd {
  display: flex;
  justify-content: end;
}
.ltr {
  direction: ltr;
}
.rtl {
  direction: rtl;
}
a {
  font-family: "Poppins-regular";
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
  color: #a64bf4;
}

body p {
  font-size: 17px;
  font-family: "Poppins-regular";
}
.jCenter {
  display: flex;
  justify-content: center;
}
.aCenter {
  display: flex;
  align-items: center;
}

.customPara {
  font-size: 16px;
  font-family: "Poppins-regular";
}

body h1 {
  font-size: 67px;
  font-family: "Poppins-bold";
  line-height: 65px;
}

body h2 {
  font-size: 60px;
  font-family: "Poppins-bold";
  line-height: 60px;
}

body h3 {
  font-size: 53px;
  font-family: "Poppins-bold";
}

body h4 {
  font-size: 34px;
  font-family: "Poppins-bold";
}

body h5 {
  font-size: 24px;
  font-family: "Poppins-bold";
}

body h6 {
  font-size: 20px;
  font-family: "Poppins-bold";
}

/*---------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
a,
svg {
  margin: 0px;
}

body p.large {
  font-size: 24px;
  font-family: "Poppins-semiBold";
}

body p.reg {
  font-size: 22px !important;
  font-family: "Poppins-bold" !important;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

/*---------------------------------------------*/

input {
  outline: none;
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* / Firefox / */
/* input[type="number"] {
  -moz-appearance: textfield;
} */

textarea {
  outline: none;
  border: none;
}

.footer_text {
  font-size: 15px !important;
  font-family: "Poppins-regular" !important;
}

.main-btn {
  background-color: var(--main-color);
  color: var(--white-clr);
  border-radius: 50px;
  outline: none;
  border: none;
}

.min_h6 {
  font-size: 20px !important;
  font-family: "Poppins-regular" !important;
}

.hero_text {
  font-size: 29px !important;
  font-family: "Poppins-bold" !important;
}

.status-active {
  background-color: #d7f8d7;
  border: 1px solid #7f7f7f;
  border-radius: 10px;
  padding: 6px 10px;
  color: green;
  font-size: 14px;
  font-family: "Poppins-medium";
  min-width: 145px;
  display: inline-block;
  text-align: center;
}

.status-pending {
  background-color: #ffa50021;
  border: 1px solid #ffa204fc;
  border-radius: 10px;
  padding: 6px 10px;
  color: #ffa204fc;
  font-size: 14px;
  font-family: "Poppins-medium";
  min-width: 145px;
  display: inline-block;
  text-align: center;
}

.status-completed {
  background-color: #f0efef;
  border: 1px solid rgb(112, 112, 112);
  border-radius: 10px;
  padding: 6px 10px;
  color: rgb(112, 112, 112);
  font-size: 14px;
  font-family: "Poppins-medium";
  min-width: 145px;
  display: inline-block;
  text-align: center;
}

.status-deactive {
  background-color: #ffe6e6;
  border: 1px solid #f04d4d;
  border-radius: 10px;
  padding: 6px 10px;
  color: red;
  font-size: 14px;
  font-family: "Poppins-medium";
  min-width: 145px;
  display: inline-block;
  text-align: center;
}

.status-modify-request {
  background-color: #d9d3f9;
  border: 1px solid #4c2df5;
  border-radius: 10px;
  padding: 6px 10px;
  color: #4c2df5;
  font-size: 14px;
  font-family: "Poppins-medium";
  min-width: 145px;
  display: inline-block;
  text-align: center;
}

.mvh-100 {
  min-height: 100vh !important;
}

.text2Line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  font-family: "Poppins-medium";
  font-size: 14px;
  color: var(--dashboard-text-color);
  text-transform: capitalize;
}
.text1Line {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;

  font-family: "Poppins-medium";
  font-size: 14px;
  color: var(--dashboard-text-color);
  text-transform: capitalize;
}
.DropdownOptionContainer__menu {
  z-index: 10 !important;
}
#composition-button {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.mvh-100 {
  min-height: 100vh !important;
}

.tooltipClass {
  width: max-content;
  display: inline-block;
}

/* Media Quories */

@media screen and (max-width: 1600px) {
  .customPara {
    font-size: 15px;
  }
}

@media screen and (max-width: 1440px) {
  body h1 {
    font-size: 64px;
  }

  body h2 {
    font-size: 54px;
  }

  body h3 {
    font-size: 52px;
  }

  body h4 {
    font-size: 30px;
  }

  .customPara {
    font-size: 15px;
  }
}

@media screen and (max-width: 1366px) {
  body h1 {
    font-size: 60px;
  }

  body h2 {
    font-size: 50px;
  }

  body h3 {
    font-size: 48px;
  }

  body h4 {
    font-size: 27px;
  }

  body h6 {
    font-size: 19px;
  }

  .customPara {
    font-size: 13.5px;
  }
}

@media screen and (max-width: 1199px) {
  body h2 {
    font-size: 46px;
  }
}

@media screen and (max-width: 991px) {
  body h1 {
    font-size: 52px;
    line-height: 50px;
  }

  body h2 {
    font-size: 40px;
    line-height: 40px;
  }

  body h3 {
    font-size: 43px;
  }

  body h4 {
    font-size: 24px;
  }

  body h6 {
    font-size: 18px;
  }

  body p {
    font-size: 16px;
  }

  .customPara {
    font-size: 13px;
  }

  body p.reg,
  button {
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  body h1 {
    font-size: 46px;
    line-height: 40px;
  }

  body h2 {
    font-size: 36px;
    line-height: 40px;
  }

  body h3 {
    font-size: 38px;
  }

  body h4 {
    font-size: 21px;
  }
}

@media screen and (max-width: 600px) {
  body h1 {
    font-size: 38px;
  }

  body h3 {
    font-size: 32px;
  }

  body h6 {
    font-size: 16px;
  }
}

@media screen and (max-width: 480px) {
  body h2 {
    font-size: 33px;
    line-height: 40px;
  }

  body p {
    font-size: 15px;
  }

  .customPara {
    font-size: 12px;
  }
}

@media screen and (max-width: 375px) {
  body h2 {
    font-size: 30px;
  }
}

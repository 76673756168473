.card {
  background: var(--white-clr);
  box-shadow: 0px 3px 6px #00000029;
  width: 100%;
}

.imgDiv{
    width: 100%;
    height: 280px;
    position: relative;
}
.imgDiv img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.imgDiv .editIcon{
    position: absolute;
    top: 20%;
    right: 10%;
    transform: translate(50%, -50%);
    width: 38px;
    height: 38px;
    border-radius: 38px;
    background-color: var(--dashboard-page-bg-clr);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.content{
    padding:32px 24px;
}
.content h6{
    color: var(--primary-clr);
    text-transform: uppercase;
}
.content p{
    color: var(--dashboard-text-gray-clr);
    font-size: 16px;
    margin-bottom: 12px;
    height: 48px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.dateAndBtn{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.dateAndBtn span{
    color: var(--primary-clr);
    font-size: 14px;
    font-family: 'Poppins-medium';
}
.dateAndBtn button{
    padding: 10px 14px;
    border-radius: 8px;
}
.header{
    background-color:var(--white-color);
    padding-block:1rem;
  }
  .resetPassword{
    background-color:var(--bg-color);
    min-height:100vh;
    display:flex;
    flex-direction:column;
  }
  .language{
    max-width:300px;
    margin-inline-start:auto;
  }
  .formWrapper{
    margin-block:auto;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
  }
  .form{
    background-color:var(--white-color);
    padding:4rem;
    max-width:800px;
    width:100%;
    border-radius:1rem;
    margin-inline:auto;
  }
  .iconWrapper{
    display:flex;
    align-items:center;
    justify-content:center;
  }
  .content{
    margin-block:2rem;
    text-align:center;
    display:flex;
    flex-direction:column;
    gap:0.5rem;
  }
  .inputWrapper{
    display:grid;
    gap:2rem;
    margin-bottom:1rem;
  }
  .btn{
    max-width:100% !important;
  }
  .backBtn{
    background-color: transparent !important;
    border: 1px solid var(--primary-clr) !important;
  }
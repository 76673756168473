.main {
  max-width: 96% !important;
  padding-block: 40px;
  height: 100vh;
  overflow-y: auto;
}
.main h5{
  color:var(--dashboard-text-gray-clr);
  margin-bottom: 0px;
}
.quillInput {
  height: calc(100vh - 250px);
  background-color: var(--white-color);
  border: 1px solid var(--7070-clr) !important;
  box-shadow: unset !important;
  border-radius: 10px;
}
.row {
  row-gap: 10px;
}
.mt30 {
  margin-top: 30px;
}
.spBtw{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

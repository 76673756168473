.fileInputDiv {
}

.fileInputDiv label {
  color: var(--dashboard-input-label-clr);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 8px;
}
.main {
  width: 100%;
  min-height: 150px;
  border: 1px dashed var(--primary-clr);
  padding: 20px;
  border-radius: 6px;
}

.section {
  /* height: 150px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imagesWrap {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.image {
  /* height: 150px; */
  /* width: 200px; */
  overflow: hidden;
  width: 125px;
  height: 102px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 6px;
  position: relative;
}
.image img {
  height: 100%;
  width: 100%;
}
.image .closeIconBtn {
  position: absolute;
  right: 5px;
  top: 5px;
  width: 24px;
  height: 24px;
  padding: 0px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.fileUploadOptions {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fileUploadOptions p {
  color: var(--dashboard-text-gray-clr);
}
.fileUploadOptions button {
  background-color: var(--primary-clr);
  color: var(--white-clr);
  font-size: 16px;
  padding: 10px 17px;
  border-radius: 10px;
  outline: none;
  border: none;
}

.main {
  /* height: 500px; */
  width: 100%;
  padding: 0px !important;
}

.labelText {
  text-transform: capitalize;
  margin-bottom: 8px;
  color: var(--dashboard-input-label-clr);
  font-size: 18px;
  font-weight: 600;
}

.labelValue {
  font-size: 18px;
  color: var(--text-black-clr);
  font-weight: 400;
  font-family: "Poppins-regular";
  text-transform: none;
}

.spBtw {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile {
  flex-shrink: 0;
  cursor: default;
}
.paddingContainer {
  padding: 50px;
}

.header {
  border-radius: 0px;
  height: 30px;
}
.header > div {
  top: 3px;
  right: 10px;
}
.row {
  height: 100%;
}

.col1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding-right: 30px;
  margin-top: 20px;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.buttonsContainer button {
  width: 200px;
}

.buttonsContainer button:first-child {
  background-color: var(--white-clr);
  color: var(--primary-clr);
  border: 1px solid var(--primary-clr);
}

.previewContainer h4 {
  color: var(--primary-clr);
}

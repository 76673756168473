.tableWrapper {
}
.tableWrapper p {
  margin-bottom: 0px;
}

.thead {
  height: 70px;
}
.thead > tr th {
  background-color: #e0f3f1;
  color: var(--575-clr);
  font-weight: 600;
}
.tableHeight {
  height: calc(100vh - 365px);
}

/* Options */
.list {
  padding: 0px;
  margin-bottom: 0px;
}
.list li{
    height: 36px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding-inline: 10px;
}
.list li:hover {
  background-color: #e0f3f1;
}
.list li span{
    color: #4E4E4E;
}
/* Options */
.tableRow td{
  height: 77px !important;
}
.iconBtn{
  padding-block: 12px;
}
.threeDots{
  color: var(--primary-clr);
  background: transparent !important;
}
.paginationDiv{
  display: flex;
  justify-content: flex-end;
  padding-block: 10px;
}